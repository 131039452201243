import Banner from "../components/sections/banner/Banner";
import Header from "../components/header/Header";
import Faqs from "../components/sections/faqs/Faqs";
import Footer from "../components/footer/Footer";
import { LandingBg } from "../components/Styled/AllImages";
import styled from "styled-components";
import Features from "../components/sections/features/Features";
import Pricing from "../components/sections/pricing/Pricing";
import Contact from "../components/sections/contact/Contact";

const Index = () => {
  return (
    <IndexWrapper bg={LandingBg}>
      <Header />
      <Banner />
      <Features />
      <Pricing/>
      <Faqs />
      <Contact/>
      <Footer />
    </IndexWrapper>
  );
};

export default Index;

const IndexWrapper = styled.div`
  background-size: cover!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background: url(${(props) => (props.bg ? props.bg : "")});
`;
