import React, { useEffect } from "react";
import { Box } from "./components/Styled/Elements";
import { privateRoute } from "./route/PrivateRoute";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Box className="main-artical">
        <Routes>
          {privateRoute.map((list, key) => (
            <Route
              key={key}
              {...list}
            />
          ))}
        </Routes>
      </Box>
    </>
  );
}

export default App;
