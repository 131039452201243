import { Anytime, Effortless, Facebook, Instagram, Realtime, Scalable, Search, Secure, Snapchat, Twitter } from "../Styled/AllImages";
import { Cabinets, Dashboard, FeaturesBg, History, HQBranches, Phone, Requests, Settings, Users } from "../Styled/AllImages";

export const NavDataConfig = [
	{
		to: "home",
		label: "Home",
	},
	{
		to: "features",
		label: "Features",
	},
	{
		to: "pricing",
		label: "Pricing",
	},
	{
		to: "faqs",
		label: "FAQs",
	},
	{
		to: "contact",
		label: "Contact",
	},
];

export const socialIcon = [
    {
        img: <Facebook/>,
        link: '#!',
    },

    {
        img: <Twitter/>,
        link: '#!',
    },
    {
        img: <Instagram/>,
        link: '#!',
    },
    {
        img: <Snapchat/>,
        link: '#!',
    },
];

export const features = [
    {
      name: "secure_storage",
      desc: 'data_is_encrypted_stored',
      icon: Secure,
    },
    {
      name: "anytime_anywhere_success",
      desc: 'access_doc_anywhere',
      icon: Anytime,
    },
    {
      name: "scalable_solutions",
      desc: 'easily_scale_your_storage',
      icon: Scalable,
    },
    {
      name: "effortless_organization",
      desc: 'categorize_and_manage_file',
      icon: Effortless,
    },
    {
      name: "realtime_collaboration",
      desc: 'work_together_seamlessly',
      icon: Realtime,
    },
    {
      name: "advance_search",
      desc: 'quickly_find_what_you_need',
      icon: Search,
    },
  ];
export const modules = [
    {
      name: "dashboard",
      icon: <Dashboard/>,
      previewImage: FeaturesBg,
    },
    {
      name: "cabinets",
      icon: <Cabinets/>,
      previewImage: FeaturesBg,
    },
    {
      name: "requests",
      icon: <Requests/>,
      previewImage: FeaturesBg,
    },
    {
      name: "history",
      icon: <History/>,
      previewImage: FeaturesBg,
    },
    {
      name: "users",
      icon: <Users/>,
      previewImage: FeaturesBg,
    },
    {
      name: "hq_branches",
      icon: <HQBranches/>,
      previewImage: FeaturesBg,
    },
    {
      name: "settings",
      icon: <Settings/>,
      previewImage: FeaturesBg,
    },
    {
      name: "support",
      icon: <Phone/>,
      previewImage: FeaturesBg,
    },
  ];
  export const pricingPlans = [
	{
		id: 1,
		plan_name: "basic_plan_free",
		desc: "quickest_way_to_try_cloud_archive",
		price: 0,
		btn_text: "get_started",
		color: "var(--themeColor2)",
		stroke: "var(--themeColor2)",
		bg: "--white",
		border: "#eaedf3",
    isBorder: false,
    isCustom: false,
    isBasic: true,
		access_features: [
			"five_archives_allowed",
			"basic_support",
			"access_from_any_device"
		]
	},
	{
		id: 2,
		plan_name: "professional_plan",
		desc: "enhanced_doc_management",
		price: 300,
		btn_text: "get_started",
		color: "var(--white)",
		stroke: "var(--themeColor)",
		bg: "--themeColor",
		border: "var(--themeColor)",
    isBorder: true,
    isCustom: false,
    isBasic: false,
		access_features: [
			"unlimited_archives",
			"priority_support",
			"access_from_any_device"
		]
	},
	{
		id: 3,
		plan_name: "custom_enterprise_plan",
		desc: "custom_solutions_for_comprehensive_doc_management",
		price: 0,
		btn_text: "contact_sales_team",
		color: "var(--themeColor2)",
		stroke: "var(--themeColor2)",
		bg: "--white",
		border: "#eaedf3",
    isBorder: false,
    isCustom: true,
    isBasic: false,
		access_features: [
			"unlimited_archives",
			"tailored_features_integration",
			"dedicated_account_manager",
      "contact_sales_team_for_customized"
		]
	},
  ]

  export const faqData = [
    {
      id: 1,
      question: "what_is_difference_basic_and_professional",
      answer: "basic_plan_offer_10gb_storage"
    },
    {
      id: 2,
      question: "how_secure_is_my_data",
      answer: "basic_plan_offer_10gb_storage"
    },
    {
      id: 3,
      question: "can_upgrade_downgrade_plan",
      answer: "basic_plan_offer_10gb_storage"
    },
    {
      id: 4,
      question: "what_enterprise_plan_include",
      answer: "basic_plan_offer_10gb_storage"
    },
    {
      id: 5,
      question: "is_there_mobile_app_available",
      answer: "basic_plan_offer_10gb_storage"
    },
  ]
