import React from "react";
import { FooterWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Box,
  HeadingStyled,
  LazyImage,
  SpanStyled,
  TextStyled,
} from "../Styled/Elements";
import { LogoEn, LogoAr } from "../Styled/AllImages";
import { socialIcon } from "../data/Data";

const Footer = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const date_year = new Date();
  const FowlUrl = () => {
    const whatsappURL = "https://fowl.sa/home";
    window.open(whatsappURL, "_blank");
  };
  const FowlWeb = () => {
    window.open("https://websites.fowl.sa/", "_blank");
  };
  return (
    <FooterWrapper className="container-fluid footerWrapper" lang={language}>
      <Box className="footerCrWrapper">
        <Container className="footerContain2">
          <Row className="footer-row align-items-center justify-content-between">
            <Box className="footerLogo">
              {language === "ar" ? (
                <LazyImage src={LogoAr} />
              ) : (
                <LazyImage src={LogoEn} />
              )}
            </Box>
            <Box className="text-center copyright">
              <SpanStyled
                className="footer-op"
                size="14px"
                color="var(--text)"
                transform="capitalize"
              >
                &copy; {date_year.getFullYear()}
                <SpanStyled
                  className="footer-op footer-op-company"
                  size="14px"
                  color="var(--themeColor)"
                  weight="600"
                >
                  {" "}
                  {t("cloud_archive")}
                </SpanStyled>
              </SpanStyled>
              <SpanStyled
                className="footer-op footer-op-allRights"
                size="14px"
                color="var(--text)"
                transform="capitalize"
              >
                {" "}
                {t("AllRightReserved2")}
              </SpanStyled>
            </Box>
            <Row className="d-flex">
              {socialIcon.map((list, key) => (
                <Col className="contact-links" key={key}>
                  <a href={list.link} target="_blank" rel="noopener noreferrer">
                    <div className="social-icon d-flex justify-content-center align-items-center">{list.img}</div>
                  </a>
                </Col>
              ))}
            </Row>
          </Row>
        </Container>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
