import styled from "styled-components";

export const FaqsWrapper = styled.section`
	.item-wrapper{
        padding-right: 15px;
    }
    .item-container{
		padding: 0;
		margin: 8px 0;
		border: 1px solid #EAEDF3;
		cursor: pointer;
        border-radius: 12px;
	}
.item-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
	padding: 10px 20px;
    .item-box-heading{
        color: var(--text);
        font-weight: 700;
        font-size: 16px;
		
    }
	.item-box-text{
		color: var(--text);
        font-size: 16px;
		margin: 0;
		padding: 0 20px 20px 20px;
	}
    & > svg{
        width: 16px;
        height: 16px;
    }
    
}

    .active-item-box{
        .item-box-heading{
            /* padding-bottom: 10px; */
            
        }
        .item-box-text{
            color: var(--text);
            font-size: 16px;
            margin: 0;
            padding: 0 20px 20px 20px;
        }
        & .border-bottom-line{
            border-bottom:1px solid #EAEDF3;
        }
        & > .item-box{
            position:relative;
            
        }
        
        
    }
    @media (max-width: 991px) {
        padding-top: 10px;
    }
`;
