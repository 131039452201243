import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Box,
  HeadingStyled,
  SubHeadingStyled,
  TextStyled,
} from "../../Styled/Elements";
import { PricingWrapper, PricingCardWrapper } from "./styled";
import { useTranslation } from "react-i18next";
import { pricingPlans } from "../../data/Data";
import { ButtonCustom, ContactButton } from "../../Styled/CustomElements";
import { ChatPopup, Check } from "../../Styled/AllImages";
import CustomModal from "./components/CustomModal";

const Pricing = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  return (
    <PricingWrapper id="pricing" lang={language} className="pt-5">
      <Container>
        <Box className="text-center">
          <HeadingStyled
            size="36px"
            color="var(--text)"
            className="main-heading"
          >
            {t("flexible_plan_for_every_business")}
          </HeadingStyled>
          <TextStyled
            color="var(--text)"
            weight="400"
            size="16px"
            className="mb-6 justify-content-center"
          >
            {t("flexible_plans_to_suit_business")}
          </TextStyled>
        </Box>
        <Box className="mt-4">
          <Row className="justify-content-center">
            {pricingPlans.map((plan, key) => (
              <Col lg={4} md={6}>
                <PricingCardWrapper
                  className={`p-4 p-md-3 custom-p-lg-4 my-2`}
                  color={plan.stroke}
                  border={plan.border}
                  isBorder={plan.isBorder}
                >
                  <SubHeadingStyled
                    weight="bold"
                    size="24px"
                    color="var(--text)"
                    className="plan-heading"
                  >
                    {t(plan.plan_name)}
                  </SubHeadingStyled>
                  <TextStyled size="16px" weight="400" color="var(--text2)" className="plan-desc">
                    {t(plan.desc)}
                  </TextStyled>
                  {plan.isCustom ? (
                    <>
                      <Box className="mb-3 pt-2">
                        <Box className="pb-2">
                          <ChatPopup />
                        </Box>
                        <TextStyled color="var(--text)" size="14px">
                          {t("contact_sales_for_pricing")}
                        </TextStyled>
                      </Box>
                    </>
                  ) : (
                    <Box className="mt-3 mb-3">
                      <SubHeadingStyled size="28px" weight="bold" color={plan.stroke}>
                        {plan.price} {t("sar")}
                      </SubHeadingStyled>
                      <TextStyled color="var(--text)" size="14px">
                        {plan.isBasic
                          ? t("free_forever")
                          : plan.isCustom
                          ? t("contact_sales_for_pricing")
                          : t("per_user_month")}
                      </TextStyled>
                    </Box>
                  )}
                  <ButtonCustom
                    label={t(plan.btn_text)}
                    bg={`var(${plan.bg})`}
                    border={plan.color}
                    color={plan.color}
                    className="w-100 justify-content-center"
                    clName="w-100"
                    {...(plan.isCustom && { onClick: handleShow })}
                  />
                  <Box className="mt-4">
                    {plan.access_features.map((ftr, i) => (
                      <Box className="d-flex mb-2">
                        <Box>
                          <Check />
                        </Box>
                        <TextStyled
                          size="16px"
                          color="var(--text)"
                          className="mx-2"
                        >
                          {t(ftr)}
                        </TextStyled>
                      </Box>
                    ))}
                  </Box>
                </PricingCardWrapper>
              </Col>
            ))}
          </Row>
        </Box>
      </Container>
      <CustomModal
        show={show}
        setShow={setShow}
        title={t("contact_our_sales_team")}
      />
    </PricingWrapper>
  );
};

export default Pricing;
