import React from "react";
import { FeaturesWrapper } from "./styled";
import {
  Box,
  HeadingStyled,
  LazyImage,
  SubHeadingStyled,
  TextStyled,
} from "../../Styled/Elements";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import FeatureSlider from "./component/Slider";
import { features, modules } from "../../data/Data";

const Features = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <FeaturesWrapper lang={language} id="features" className="pt-5">
      <Container>
        <Box className="text-center">
          <HeadingStyled
            color="var(--text)"
            weight="700"
            size="36px"
            className="main-heading"
          >
            {t("secure_scalable_accessible_management")}
          </HeadingStyled>
          <TextStyled
            color="var(--text)"
            weight="400"
            size="16px"
            className="mt-3 mb-4"
          >
            {t("our_system_offers_robust_solution")}
          </TextStyled>
        </Box>
        <Box className="d-flex justify-content-center">
          <FeatureSlider modules={modules} />
        </Box>
        <Box className="feature-box mt-4 text-center">
          <HeadingStyled
            size="36px"
            color="var(--white)"
            className="main-heading"
          >
            {t("powerful_storage_features")}
          </HeadingStyled>
          <TextStyled
            color="var(--white)"
            weight="400"
            size="16px"
            className="mb-6 justify-content-center"
          >
            {t("discover_essential_tools")}
          </TextStyled>
          <Box>
            <Row>
              {features.map((feature, key) => (
                <Col lg={4} md={6} key={key}>
                  <Box className="feature-card my-2 d-flex">
                    <Box>
                      <Box className="feature-icon">
                        <LazyImage src={feature.icon} />
                      </Box>
                    </Box>
                    <Box className="mx-2 feature-text">
                      <SubHeadingStyled color="var(--white)" size="18px" weight="700">
                        {t(feature.name)}
                      </SubHeadingStyled>
                      <TextStyled color="var(--white)" size="16px" weight="300">
                        {t(feature.desc)}
                      </TextStyled>
                    </Box>
                  </Box>
                </Col>
              ))}
            </Row>
          </Box>
        </Box>
      </Container>
    </FeaturesWrapper>
  );
};

export default Features;
