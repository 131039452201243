import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { Box, LazyImage } from "../../../Styled/Elements";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FeatureSlider = ({ modules }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [selectedModule, setSelectedModule] = useState({});
  const [sliderKey, setSliderKey] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    className: "center",
    centerMode: true,
    rtl: language == "ar",
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    focusOnSelect: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      // {
      //   breakpoint: 991,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
      // {
      //   breakpoint: 576,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  const handleSlideChange = (slide) => {
    setSelectedModule(slide);
  };

  useEffect(() => {
    if (modules.length > 0) setSelectedModule(modules[0]);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
    setSliderKey((prevKey) => prevKey + 1);
  }, [modules, language]);

  return (
    <SliderStyled>
      <Box dir="ltr" className={`slider-container`}>
        {modules.length > 0 && (
          <Slider ref={sliderRef} key={sliderKey} {...settings}>
            {modules.map((module, index) => (
              <Box
                key={index}
                className="module-icon-container"
                onClick={() => handleSlideChange(module)}
              >
                <Row className="justify-content-center">
                  <Box className="module-icon">
                    <Box>{module.icon}</Box>
                    <Box className="slide-name text-center">{t(module.name)}</Box>
                  </Box>
                </Row>
              </Box>
            ))}
          </Slider>
        )}
      </Box>

      <Box>
        <Box className="module-preview">
          <LazyImage
            src={selectedModule.previewImage}
            alt={selectedModule.name}
            className="preview-image"
          />
        </Box>
      </Box>
    </SliderStyled>
  );
};

export default FeatureSlider;
const SliderStyled = styled.div`
  width: 100%;

  /* .slider-container {
    direction: rtl!important;
  } */
  .module-icon-container {
    width: 100px;
    padding: 10px;
    cursor: pointer;
  }
  .module-icon-container svg {
    [stroke] {
      stroke: var(--iconColor);
    }
    [fill] {
      fill: var(--iconColor);
    }
  }
  .module-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0;
    max-width: 100px;
    height: 100px;
    border-radius: 10px;
  }
  .slide-name {
    color: var(--iconColor);
  }
  .slick-current {
    .module-icon-container svg {
      [stroke] {
        stroke: var(--white);
      }
      [fill] {
        fill: var(--white);
      }
    }
    .module-icon {
      background: var(--themeGradient);
    }
    .slide-name {
    color: var(--white);
  }
  }

  /* .module-icon {
    width: 50px;
    height: 50px;
    object-fit: cover;
  } */

  .module-preview {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }

  .module-preview img {
    width: 100%; /* Ensures the image takes up 100% of its container */
    max-width: 700px; /* Limits the image width for larger screens */
    height: auto; /* Maintains the aspect ratio */
    object-fit: cover; /* Ensures the image covers the container properly */
  }

  .preview-image {
    width: 100%; /* This ensures that the image container is 100% of the available width */
  }
`;
