import styled from "styled-components";

export const FeaturesWrapper = styled.section`
    .feature-box {
        padding: 2rem;
        background: var(--themeGradient);
        border-radius: 16px;
    }
    .feature-card{
        box-shadow: 0px 19.2px 27.6px -9px #064A931A;
        background: #FFFFFF33;
        border-radius: 9px;
        height: 178px;
        padding: 1.3rem;
    }
    .feature-text{
        text-align: start;
    }
    .feature-icon {
        width: 66px;
        height: 66px;
    }
    .feature-icon img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`