import styled from "styled-components";

const PricingWrapper = styled.section``;

const PricingCardWrapper = styled.div`
  height: 500px;
  box-shadow: 0px 19.2px 27.6px -9px #064a931a;
  border: 1px solid ${({border}) => border ? border : "#eaedf3"};
  border-radius: 9px;
  svg {
    [stroke]{
      stroke: ${({color}) => color ? color : 'var(--themeColor2)'}!important;
    }
  }
`;

export { PricingWrapper, PricingCardWrapper };
